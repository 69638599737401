<template>
  <div class="lz-dialog">
    <el-dialog
      v-model="dialogShow"
      :title="obj.dialogTitle"
      :width="obj.dialogWidth"
      :before-close="close"
      draggable
    >
      <slot>
        <el-tree
          :props="props"
          :load="loadNode"
          lazy
          show-checkbox
          @check-change="handleCheckChange"
        />
      </slot>
      <template #footer>
        <span v-if="obj.dialogIsFooter" class="dialog-footer">
          <el-button class="btn-mixins-clear-default" @click="close">{{
            obj.dialogCloseBtnText
          }}</el-button>
          <el-button class="btn-mixins dia-suc" @click="success">{{
            obj.dialogSuccessBtnText
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  props: {
    dialogObject: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  emits: ["dialogClose", "dialogSuccess"],
  setup(props, ctx) {
    // const dialogObj = reactive(inject('dialogObj'))
    // 处理props的数据 重新定义 dialogVisible
    const dialogShow = computed({
      get() {
        // getter
        return props.dialogObject.dialogVisible;
      },
      set(val) {
        // console.log(val)
        return val;
      },
    });
    const obj = {
      dialogTitle: props.dialogObject.title || "标题",
      dialogWidth: props.dialogObject.width || "30%",
      dialogCloseBtnText: props.dialogObject.closeBtnText || "取消",
      dialogSuccessBtnText: props.dialogObject.successBtnText || "成功",
      dialogIsFooter: props.dialogObject.isFooter || false,
    };
    const close = () => {
      ctx.emit("dialogClose", false, "close");
    };
    const success = () => {
      ctx.emit("dialogSuccess", false, "success");
    };
    return { obj, dialogShow, close, success };
  },
};
</script>
<style lang="scss" scope>
.lz-dialog {
  .dia-suc {
    margin-left: 16px !important;
  }
}
</style>
